import React from "react";
import LocalBeatAppBar from "../components/LocalBeatAppBar"; // Assuming this is the correct path

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        // backgroundImage: theme.backgroundImage,  // Apply background image, going with just color for now, might add back in later so leaving commented
        backgroundSize: "cover", // Ensure the image covers the whole area
        backgroundPosition: "center", // Center the image
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
    >
      <LocalBeatAppBar />
      <main className="main-content">{children}</main>
      <footer
        style={{
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <p style={{ margin: 0 }}>&copy; 2024 LocalBeat. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Layout;
