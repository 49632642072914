import React, { createContext, useContext, useEffect, useState } from "react";
import {
  createTheme,
  CssBaseline,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import { debugLog } from "../util/logger";
import { Theme, ThemeContextType } from "../types/Theme";

const defaultTheme: Theme = {
  // Text and Foreground Colors
  color: "#13143a", // textColor
  textDecorationColor: "#13143a", // Matches textColor for consistency

  // Button Colors
  buttonBackgroundColor: "#eaeaea",
  buttonTextColor: "#505668",

  // Background Colors
  backgroundColor: "#FFFFFF", // backgroundColor

  // Border and Outline Colors
  borderColor: "#eaeaea", // inputBorderColor
  outlineColor: "#eaeaea", // Matches inputBorderColor
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  useEffect(() => {
    async function fetchTheme() {
      try {
        // Fetch theme from API or local storage in the future
        // const response = await fetch("/api/theme");
        // const data: Theme = await response.json();
        // setTheme(data);
      } catch (error) {
        debugLog("Failed to fetch theme, using default.");
      }
    }

    fetchTheme();
  }, []);

  const muiTheme = createTheme({
    palette: {
      mode: "light",
      background: {
        default: theme.backgroundColor,
        paper: theme.backgroundColor,
      },
      text: {
        primary: theme.color,
      },
      divider: theme.borderColor,
    },
    typography: {
      fontSize: 14, // Base font size
      // Responsive typography for all headings
      h1: {
        fontSize: "2.5rem",
        fontWeight: 700,
      },
      h2: {
        fontSize: "2rem",
        fontWeight: 600,
      },
      h3: {
        fontSize: "1.75rem",
        fontWeight: 500,
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 500,
      },
      h5: {
        fontSize: "1.25rem",
        fontWeight: 400,
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 400,
      },
      body1: {
        fontSize: "1rem",
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "0.875rem",
        lineHeight: 1.43,
      },
      button: {
        textTransform: "none",
        fontWeight: 600,
      },
      caption: {
        fontSize: "0.75rem",
        lineHeight: 1.66,
      },
      overline: {
        fontSize: "0.75rem",
        textTransform: "uppercase",
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: theme.backgroundColor,
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderColor: theme.borderColor,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: theme.buttonBackgroundColor,
            color: theme.buttonTextColor,
            "&:hover": {
              backgroundColor: theme.buttonBackgroundColor,
            },
          },
        },
      },
    },
  } as ThemeOptions);

  return (
    <CssBaseline>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <MUIThemeProvider theme={muiTheme}>
          <div style={theme}>{children}</div>
        </MUIThemeProvider>
      </ThemeContext.Provider>
    </CssBaseline>
  );
};

// If you need to use a non-standard style from the theme, you can useTheme in a component
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
