import { Category } from "../../types";

let cachedCategories: Category[] | null = null; // Cache to store categories

// Function to process the incoming data and organize subcategories
function processCategories(categories: Category[]): Category[] {
  const categoryMap: { [key: string]: Category } = {};

  // First, map all categories by their own categoryCode
  categories.forEach((category) => {
    categoryMap[category.categoryCode] = { ...category, subcategories: [] };
  });

  // Then, link subcategories to their parent categories
  categories.forEach((category) => {
    if (category.parent && category.parent.categoryCode) {
      const parentCategoryCode = category.parent.categoryCode; // Extract parent categoryCode
      if (categoryMap[parentCategoryCode]) {
        categoryMap[parentCategoryCode].subcategories?.push(category); // Add this category as a subcategory to its parent
      }
    }
  });

  // Return only top-level categories (those without a parent)
  return Object.values(categoryMap).filter((category) => !category.parent);
}

export async function getCategories(): Promise<Category[]> {
  if (cachedCategories) {
    return cachedCategories; // Return cached data if available
  }

  try {
    const response = await fetch("/api/category/getAll", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch categories");
    }

    const data = await response.json();

    // Validate that data.data is an array of categories
    if (!Array.isArray(data.data)) {
      throw new Error("Invalid response structure");
    }

    // Process the incoming data
    cachedCategories = processCategories(data.data); // Cache the processed categories
    return cachedCategories;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
}
