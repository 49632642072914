// src/store/searchSlice.ts

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category, Event, SearchParameters, View } from "../types";
import { getCategories, searchPrograms } from "../api/search";
import dayjs from "dayjs";

// Constants for default values
const DEFAULT_RADIUS = 10; // Default mile radius
const INITIAL_END_DATE_OFFSET_DAYS = 7;

// Set default dates
const defaultStartDate = dayjs().toISOString();
const defaultEndDate = dayjs()
  .add(INITIAL_END_DATE_OFFSET_DAYS, "day")
  .toISOString();

interface SearchState {
  searchParams: SearchParameters;
  events: Event[];
  loading: boolean;
  view: View;
  error: string | null;
  selectedCategories: string[];
  filteredCategories: string[];
  categories: Category[];
}

const initialState: SearchState = {
  searchParams: {
    coords: undefined,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    radius: DEFAULT_RADIUS,
    activity: "",
  },
  events: [],
  loading: false,
  view: View.LIST,
  error: null,
  selectedCategories: [],
  filteredCategories: [],
  categories: [],
};

export const fetchEvents = createAsyncThunk(
  "search/fetchEvents",
  async (searchParams: SearchParameters, { rejectWithValue }) => {
    try {
      const response = await searchPrograms(searchParams);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

export const fetchCategories = createAsyncThunk(
  "search/fetchCategories",
  async (_, { rejectWithValue }) => {
    try {
      return await getCategories();
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  },
);

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchParams(state, action: PayloadAction<Partial<SearchParameters>>) {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
    setView(state, action: PayloadAction<View>) {
      state.view = action.payload;
    },
    setSelectedCategories(state, action: PayloadAction<string[]>) {
      state.selectedCategories = action.payload;
    },
    setFilteredCategories(state, action: PayloadAction<string[]>) {
      state.filteredCategories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchEvents.fulfilled,
        (state, action: PayloadAction<Event[]>) => {
          state.loading = false;
          state.events = action.payload;
        },
      )
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchCategories.pending, (state, action) => {
        state.error = null;
      })
      .addCase(
        fetchCategories.fulfilled,
        (state, action: PayloadAction<Category[]>) => {
          state.categories = action.payload;
        },
      )
      .addCase(fetchCategories.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const {
  setSearchParams,
  setView,
  setSelectedCategories,
  setFilteredCategories,
} = searchSlice.actions;

export default searchSlice.reducer;
