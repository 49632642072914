import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as Logo } from "../resources/LocalBeatLogo.svg";
import { ReactComponent as LocalBeatName } from "../resources/LocalBeatLogoName.svg";
import { Link } from "react-router-dom";

import { IconButton, MenuItem, Typography } from "@mui/material";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

function LocalBeatAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <AppBar position="sticky" style={{ boxShadow: "none" }} id="Appbar">
      <Toolbar disableGutters id="Toolbar">
        <Box
          component={Link}
          to={"/"}
          sx={{
            display: { xs: "flex" },
            p: 1,
            height: "100%",
          }}
          alignItems="center"
          columnGap={1}
        >
          <Logo id="logo" aria-label="Local Beat Logo" />
          <LocalBeatName id="local-beat-logo" aria-label="Local Beat Logo" />
        </Box>
        <Box sx={{ flexGrow: 0, marginLeft: "auto" }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Profile" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default LocalBeatAppBar;
